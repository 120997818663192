import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function PrivacyPolicyPage() {
  return (
    <Layout>
      <SEO keywords={[`sparkrise`, `privacy policy`]} title="Privacy Policy" />

      <article className="mt-12">
        <heading className="container">
          <h2 className="c-h3 text-center text-black px-4">
            Privacy Policy
          </h2>
          <h3 className="text-center text-black px-4">
            Updated January 2021
          </h3>
        </heading>
        <div className="lg:w-8/12 mx-auto px-4 prose max-w-none">
          <body lang="en-US" link="#0563c1" vlink="#800000" dir="ltr">
            <p>Thanks for visiting SparkRise&#39;s Privacy Policy.</p>
            <p>Our Services are defined in our <a href="/termsofservice">Terms of Service.</a></p>
            <p>This Policy describes the information we collect from you and how we use this information. It applies to any of the Services that SparkRise provides.</p>
            <p>We are committed to protecting your information and being transparent about the ways we use it.</p>
            <p>What We Collect</p>
            <p>To create an account on SparkRise and use our Services, we need to collect and process some information. Depending on your use of the Services, this information may include:</p>
            <p>Information that you provide by completing forms on SparkRise such as your name, email, address, telephone number, country of residence, login, and password details. If you are a brand sponsor or social good cause, information may also include organization name and address. We may ask for this information if you register as a user of the Services, subscribe to our newsletters, upload or submit any material through SparkRise, or request any information;</p>
            <p>Details of any requests or transactions you make through the Services. All payment information you submit is collected and used by our payment processors in accordance with their privacy policies. SparkRise doesn&#39;t store your payment information apart from the last four digits of your account, expiration date, and country, which we require for tax, government regulatory, and security purposes;</p>
            <p>Information about your activity on and interaction with SparkRise (such as your IP address, the type of device or browser you use, and your actions on the site);</p>
            <p>Communications you send to us (for example, when you ask for support, send us questions or comments, or report a problem); and</p>
            <p>Information that you submit to SparkRise in the form of comments, contributions to discussions, or messages to other users.</p>
            <p>How We Use this Information</p>
            <p>We use the information we collect for the following purposes:</p>
            <p>To keep your account secure;
		<br />To enable us to provide you with our Services, and to improve, promote, and protect our Services;
		<br />To set up and administer your account, contact you, and customize your experience on SparkRise (for example, to show you sparkrise campaigns that we think may interest you based on your previous use); and
		<br />To track use of the Services so that we can improve SparkRise.</p>
            <p>How this Information is Shared</p>
            <p>1. The following information is shared publicly:</p>
            <p>When you create an account, we may create a basic profile page for you on SparkRise, containing your account name, the date the account was created, and a list of sparkrise campaigns you have sponsored, backed or launched. In this event, whenever your account name appears on SparkRise (for instance, when you post comments, send messages, or back sparkrise campaigns), people can click your account name to see your profile. Here are some of the things that may be publicly viewable on your profile, or elsewhere on SparkRise:</p>
            <p>The account name, and the date it was created;
		<br />Any information you choose to add to your profile (like a picture, bio, or your location);
		<br />Sparkrise campaigns you&#39;ve sponsored or backed (if you are an individual backer, we will not disclose your contribution amounts);
		<br />Sparkrise campaigns you&#39;ve launched;
		<br />Any comments you&#39;ve posted on SparkRise; and
		<br />If you have &#39;Liked&#39; a sparkrise campaign update.</p>
            <p>Please note that each sparkrise campaign page may list the community of users who have backed the sparkrise campaign. If you don&#39;t want others to see your name or profile image on a sparkrise campaign&#39;s Community tab, choose an account image or name that is not identifiable (e.g., Treehugger17).</p>
            <p>Organizations or individuals that create a sparkrise campaign will be asked to verify their identities before launching the campaign. Once this has been done, the organizer&#39;s verified name will be publicly displayed on their account profile and on any sparkrise campaigns they launch.</p>
            <p>2. The following information is not shared publicly or to other users:</p>
            <p>Any payment information you provide;
		<br />Your password details;
		<br />Your IP address;
		<br />Your phone number; and
		<br />Communications you send to us (for example, when you ask for support, send us questions or comments, or report a problem).</p>
            <p>We will not post anything to your Facebook, Twitter, or other third-party accounts without your permission. We do not and will not sell your personal data.</p>
            <p>3. Some information may be shared with certain trusted third-party services to help us provide, improve, promote, or protect SparkRise&#39;s Services (for example, when we partner with payment processors, or use services that help us manage our ads on other sites).</p>
            <p>When we share data with third-party services that support our delivery of the SparkRise Services, we require that they use your information only for the purposes we&#39;ve authorized, and that they protect your personal information at least to the same standards we do;</p>
            <p>We may also share information that&#39;s aggregated and made anonymous so that it doesn&#39;t directly identify you.</p>
            <p>4. The following information is shared with sparkrise campaign organizers &amp; participants:</p>
            <p>When you back a sparkrise campaign, the sparkrise campaign&#39;s organizer will know your account name, the amount you have donated, and the campaign incentive you accepted. Organizers never receive backers&#39; credit card details or other payment information;</p>
            <p>If you “opted in” to provide your email address to a sparkrise campaign organizer, sponsor or social good cause, the email address associated with your SparkRise account will be provided to said organization or individual;</p>
            <p>Organizers, sponsors and social good causes may also receive anonymized information about the ways people visit and interact with their Sparkrise campaign pages, in the form of routine traffic analytics.</p>
            <p>5. Information that&#39;s shared to protect SparkRise &amp; comply with the law includes the following:</p>
            <p>We reserve the right to disclose personal information when we believe that doing so is reasonably necessary to comply with the law or law enforcement, to prevent fraud or abuse, or to protect SparkRise&#39;s legal rights.</p>
            <p><u>Information We Collect Through Your Use of the Services</u>
            </p>
            <p>We may collect certain information automatically when you use the Services. This information may include your Internet protocol (IP) address, user settings, MAC address, cookie identifiers, mobile carrier, mobile advertising and other unique identifiers, details about your browser, operating system or device, location information (including inferred location based off of your IP address), Internet service provider, pages that you visit before, during and after using the Services, information about the links you click, information about how you interact with the Services, including the frequency and duration of your activities, and other information about how you use the Services. Information we collect may be associated with accounts and other devices.</p>
            <p>We, as well as third parties that provide content, advertising, or other functionality on the Services, may use cookies, pixel tags, local storage, and other technologies (“Technologies”) to automatically collect information through the Services. Technologies are essentially small data files placed on your device that allow us and our partners to record certain pieces of information whenever you visit or interact with our Services.</p>
            <ul>
              <li>
                <p>Cookies are small text files placed in device browsers to store their preferences. Most browsers allow you to block and delete cookies. However, if you do that, the Services may not work properly.</p>
              </li>
              <li>
                <p>A pixel tag (also known as a web beacon) is a piece of code embedded in the Services that collects information about engagement on the Services. The use of a pixel allows us to record, for example, that a user has visited a particular web page or clicked on a particular advertisement. We may also include web beacons in e-mails to understand whether messages have been opened, acted on, or forwarded.</p>
              </li>
            </ul>
            <p>&nbsp;We may also use Google Analytics and other service providers to collect and process analytics information on our Services. For more information about Google Analytics, please visit&nbsp;<a href="https://www.google.com/policies/privacy/partners/">www.google.com/policies/privacy/partners/</a>. You can opt out of Google&#39;s collection and processing of data generated by your use of the Site by going to&nbsp;<a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.</p>
            <p>Additional</p>
            <p>We want to communicate with you only if you want to hear from us. We try to keep emails to a minimum and give you the ability to opt out of any marketing communications we send;</p>
            <p>We will send you email relating to your transactions on SparkRise. You will also receive certain marketing email communications, in accordance with your preferences, and from which you may opt out of at any time;</p>
            <p>We&#39;ll also send you service-related announcements when it&#39;s necessary to do so;</p>
            <p>To modify or delete the personal or organizational information you&#39;ve provided, please log in and update your profile. We may retain certain information as required by law or for necessary business purposes;</p>
            <p>On request, we will give you a copy of all the personal or organizational information about you that we hold. This information is subject to a fee not exceeding the prescribed fee permitted by law;</p>
            <p>People under 18 (or the legal age in your jurisdiction) are not permitted to create a SparkRise campaign or manage funds and/or contributions on their own, therefore, this privacy policy makes no provision for their use of the site or platform;</p>
            <p>Information that you provide may be transferred to countries other than where you reside (for example, to our servers in the United States). We will protect all information we receive in accordance with this Privacy Policy.</p>
          </body>
        </div>
      </article>
    </Layout>
  );
}

export default PrivacyPolicyPage;
